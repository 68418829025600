<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  function getRem() {
    var oWidth = document.body.clientWidth;
    document.documentElement.style.fontSize = oWidth / 7.5 + 'px';
  }
  getRem();
  window.addEventListener("resize", getRem, false); // 动态监听window的变化

  export default {
    data() {
      return {

      }
    },
    created() {
      let arr = window.location.search.split('&')
      console.log(arr)
      this.$global.token = arr[0].split('=')[1]
      this.$global.uniacid = arr[1].split('=')[1]
      this.$global.goodsinfo = arr[2].split('=')[1]
    }
  }
</script>

<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    color: #333333;
  }

  body {
    margin: 0;
  }

  html,
  body {
    height: 100%;
  }

  image {
    vertical-align: bottom;
  }
</style>
