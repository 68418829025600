import Vue from 'vue'

import store from './store'
import Vant from "vant";
import "vant/lib/index.css";

import App from "./App.vue";
import router from "./router";
import global from './utils/global.js'
import handleMsg from './utils/handleMsg.js'
import request from './utils/request.js'
import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle)

Vue.use(Vant);

Vue.config.productionTip = false

Vue.prototype.$global = global
Vue.prototype.$handleMsg = handleMsg
Vue.prototype.$request = request

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
